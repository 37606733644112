<template>
  <kinesis-element :strength="10">
    <pre class="text-sm md:text-base rotate-[-12deg] opacity-[.1]">
      body {
        color: {{ state.color }};
        background-color: {{ state.bgColor }};
      }
  </pre>
  </kinesis-element>
</template>

<script setup>
import { inject, watchEffect, reactive } from 'vue';
import { KinesisElement } from 'vue-kinesis';

const theme = inject('theme');

const allThemes = {
  dark: {
    color: 'white',
    bgColor: 'black',
  },
  light: {
    color: 'black',
    bgColor: 'white',
  },
};

const getThemeColors = (activeTheme) => {
  let value = activeTheme;
  if (!allThemes[activeTheme]) {
    value = 'light';
  }
  return {
    color: allThemes[value].color,
    bgColor: allThemes[value].bgColor,
  };
};

const state = reactive(getThemeColors(theme.value));

watchEffect(() => {
  const { color, bgColor } = getThemeColors(theme.value);
  state.color = color;
  state.bgColor = bgColor;
});
</script>
