<template>
  <main :class="state.defaultTheme">
    <kinesis-container>
      <div class="bg-white min-h-screen dark:bg-[#121212] text-black
      dark:text-[#f0f0f0] font-body">
        <div class="grid grid-rows-[1fr_min-content] divide-y
        divide-dashed divide-black/10 dark:divide-white/10 max-w-screen-2xl mx-auto">
          <Layout />
          <Footer :updateTheme="updateTheme" :theme="state.defaultTheme" />
        </div>
      </div>
    </kinesis-container>
  </main>
</template>

<script setup>
import { reactive, provide, computed } from 'vue';
import { KinesisContainer } from 'vue-kinesis';
import Footer from './components/Footer.vue';
import Layout from './components/Layout.vue';

// Get the default theme from local storage
let defaultTheme = localStorage.getItem('theme');
// Check if local storage value is empty and dark mode is on
if (!defaultTheme && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  // Set deafult to dark mode
  defaultTheme = 'dark';
}

const state = reactive({
  defaultTheme,
});

const updateTheme = (value) => {
  state.defaultTheme = value;
};

provide('theme', computed(() => state.defaultTheme));

</script>
