<template>
  <section class="section-body transition" :class="{'section-body--active': isActive}"
    @mouseenter="handleMouseEnter"
    @focusin="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @focusout="handleMouseLeave">
    <slot />
  </section>
</template>

<script setup>
import { defineProps, watch, ref } from 'vue';
import { useActiveSection } from '../hooks/mouse-tracker';

const props = defineProps({
  name: String,
});

const {
  activeSection,
  handleMouseEnter,
  handleMouseLeave,
} = useActiveSection(props?.name);

const isActive = ref(activeSection.value === props?.name);

watch(activeSection, (newState) => {
  isActive.value = newState === props?.name;
});
</script>
