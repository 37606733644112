<template>
  <div class="flex space-x-3 justify-center py-4 lg:my-0 items-center">
    <span class="w-7 opacity-100 dark:opacity-50">
      <IconSun />
    </span>
    <Toggle
      trueValue="dark"
      falseValue="light"
      v-model="state.theme"
      @change="toggleMode"
      :classes="toggleClass" />
    <span class="w-7 opacity-20 dark:opacity-100">
      <IconMoon />
    </span>
  </div>
</template>

<script setup>
import { onMounted, computed, reactive } from 'vue';
import Toggle from '@vueform/toggle';
import IconSun from './Icons/Sun.vue';
import IconMoon from './Icons/Moon.vue';

const emit = defineEmits(['change']);

const props = defineProps({
  defaultTheme: String,
});

const state = reactive({
  theme: '',
});

onMounted(() => {
  state.theme = props.defaultTheme;
});

const toggleMode = (value) => {
  localStorage.setItem('theme', value);
  emit('change', value);
};

const toggleClass = computed(() => ({
  handle: 'toggle-handle',
  handleOn: 'toggle-handle-on',
  handleOff: 'toggle-handle-off',
}));
</script>

<style src="@vueform/toggle/themes/default.css"></style>
