import { ref } from 'vue';

const activeSection = ref('hero');

export function useActiveSection(name) {
  function handleMouseEnter() {
    activeSection.value = name;
  }

  return {
    activeSection,
    handleMouseEnter,
  };
}

export default useActiveSection;
