<template>
  <div class="relative">
    <kinesis-element :strength="20">
      <div
        :style="{width: smallSize, height: smallSize}"
        class="absolute border-2 opacity-60 dark:opacity-[0.1] rounded-full
        border border-black dark:border-white"></div>
    </kinesis-element>
    <kinesis-element v-if="showLarge" :strength="38">
      <div
        :style="{width: bigSize, height: bigSize, left: leftSpace + 'px', top: topSpace + 'px'}"
        class="absolute border-2 opacity-20 dark:opacity-[0.05] rounded-full
        border border-black dark:border-white"></div>
    </kinesis-element>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { KinesisElement } from 'vue-kinesis';

const props = defineProps({
  size: Number,
  topSpace: Number,
  leftSpace: Number,
  showLarge: Boolean,
  multiplier: Number,
});

const sizesInPx = {
  small: `${props.size}px`,
  big: `${props.size * props.multiplier}px`,
};

const bigSize = computed(() => (sizesInPx.big));
const smallSize = computed(() => (sizesInPx.small));
</script>
